<template>
    <Card>
        <div slot="title">{{ title }}</div>
        <!-- <div class="form_item">
            <div class="form_label must_input">订单取消时间 拍下订单时间</div>
            <Input class="width_100" type="text" v-model.trim="formData.timeOrderCancel" :maxlength="3"></Input>
            <div class="ml_10">分钟内未付款，自动取消订单</div>
        </div> -->
        <div class="form_item" v-for="(info, key) in row" :key="key">
            <div class="form_label must_input">{{ (info.title || ',').split('XX')[0] || '' }}</div>
            <Input class="width_100" type="text" v-model.trim="info.value" :maxlength="3"></Input>
            <div class="ml_10">{{ (info.title || ',').split('XX')[1] || '' }}</div>
        </div>

        <div class="mt_30relative flex justify_center">
            <Button type="primary" @click="onConfirmEdit">保存</Button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import { reqOrderSaveSetting, reqOrderSetting } from '@/lib/request/auth2';
import { isPositiveInteger } from '@/lib/util';

export default {
    name: 'goodParamsAdd',
    components: {
        //
    },
    data() {
        return {
            title: '通用设置',
            maxLength: 5,
            row: {},
            isEdit: false,
        };
    },
    mounted() {
        this.routeIdInject();
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        //获取详情
        getDetail() {
            this.showAjaxLoading();
            reqOrderSetting()
                .then(res => {
                    let row = res?.data?.row || {};
                    this.row = row;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let row = this.row;
            for (let key in row) {
                if (!isPositiveInteger(row[key].value)) {
                    row[key].value = 0;
                }
            }
            console.log(JSON.stringify(row, null, '\t'));
            reqOrderSaveSetting({
                row,
            })
                .then(res => {
                    this.fadeAlert(res.errmsg);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
<style lang="less" scoped>
.form_label,
.form_label_space {
    width: 300px;
}
</style>
